<div class='w-full p-4 pb-0'>
  <form class='flex flex-col sm:flex-row' [formGroup]='filterForm'>
    @if (showDateFields) {
      <mat-form-field class='dashboard-filter basis-1/6 px-2 max-h-[82px]'>
        <mat-label>Start Date(DD/MM/YYYY)</mat-label>
        <input formControlName='startDate'
               [matDatepicker]='fromDatePicker'
               data-cy='from-date-input'
               matInput>
        <mat-datepicker-toggle [for]='fromDatePicker' matSuffix data-cy="from-datepicker-toggle"></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker></mat-datepicker>
        <mat-error>Start date is invalid</mat-error>
      </mat-form-field>
      <mat-form-field class='dashboard-filter basis-1/6 px-2 max-h-[82px]'>
        <mat-label>End Date(DD/MM/YYYY)</mat-label>
        <input formControlName='endDate'
               [matDatepicker]='toDatePicker'
               data-cy='from-date-input'
               matInput>
        <mat-datepicker-toggle [for]='toDatePicker' matSuffix data-cy="from-datepicker-toggle"></mat-datepicker-toggle>
        <mat-datepicker #toDatePicker></mat-datepicker>
        <mat-error>End date is required</mat-error>
      </mat-form-field>
    }
    <mat-form-field class='dashboard-filter basis-6/12 px-2 max-h-[82px]'>
      <mat-label>Filter by floors</mat-label>
      <mat-select class='max-h-[30px]' (selectionChange)="updateSelectableTags()" [formControl]="floors" multiple>
        <mat-select-trigger class='max-h-[30px]'>
          <mat-chip-set>
            @for(floor of chipsForFloors; track floor) {
              <mat-chip [ngClass]="'chip-color-green'" [removable]="true" (removed)="onFloorRemove(floor)">
                {{ floor.name }}
                @if (!floor.name.endsWith('+More')) {
                  <mat-icon matChipRemove>cancel</mat-icon>
                }
              </mat-chip>
            }
          </mat-chip-set>
        </mat-select-trigger>
        @for(floor of allFloors; track floor) {
          <mat-option [value]="floor">{{floor.name}}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field class='dashboard-filter basis-6/12 px-2 max-h-[82px]'>
      <mat-label>Filter by tags</mat-label>
      <mat-select class='max-h-[30px]' [formControl]="tags" multiple>
        <mat-select-trigger class='max-h-[30px]'>
          <mat-chip-set>
            @for(tag of chipsForTags; track tag) {
              <mat-chip [ngClass]="'chip-color-red'" [removable]="true" (removed)="onTagRemove(tag)">
                {{ tag.name }}
                @if (!tag.name.endsWith('+More')) {
                  <mat-icon matChipRemove>cancel</mat-icon>
                }
              </mat-chip>
            }
          </mat-chip-set>
        </mat-select-trigger>
        @for(tag of selectableTags; track tag) {
          <mat-option [value]="tag">{{tag.name}}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <div class='flex flex-row-reverse'>
      <button [disabled]='filterForm.invalid || !filterForm.dirty' class='action-button apply-btn mx-1' matTooltip='Apply Filters' (click)='apply()' mat-button color='primary'>
        <i class="material-icons">fact_check</i>
      </button>
      <button [disabled]='!filterForm.dirty' class='action-button reset-btn mx-1' matTooltip='Reset Filters' (click)='reset()' mat-button color='warn'>
        <i class="material-icons">refresh</i>
      </button>
    </div>
  </form>
</div>
